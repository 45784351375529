<template>
  <div class="term-details-container wrapper">
    <CRow class>
      <CCol lg="12" md="12">
        <CCard>
          <CCardHeader>
            <router-link :to="termsLink">
              <CIcon name="cil-arrow-left" class="mr-1" />
              {{$t("TERM_DETAILS_COMPONENT_BACK_TO_TERMS")}}
            </router-link>
          </CCardHeader>

          <CCardBody v-if="isLoading">
            <vcl-list></vcl-list>
          </CCardBody>

          <CCardBody>
            <ValidationObserver ref="form" v-if="!isLoading" v-slot="{ handleSubmit, dirty }">
              <CForm @submit.prevent="handleSubmit(onSubmit)">
                <CRow class="form-group" v-if="isEdit">
                  <CCol lg="2" md="2">
                    <label class="col-form-label">{{$t("TERM_DETAILS_COMPONENT_TERM_NO")}}</label>
                  </CCol>
                  <CCol lg="4" md="4">
                    <div class="form-control-plaintext">{{termDetails.term_no}}</div>
                  </CCol>
                </CRow>

                <CRow class="form-group">
                  <CCol lg="2" md="2">
                    <label class>{{$t("TERM_DETAILS_COMPONENT_APPLIED_DATE_LABEL")}}</label>
                  </CCol>
                  <CCol lg="4" md="4">
                    <ValidationProvider
                      name="TERM_DETAILS_COMPONENT_APPLIED_DATE_FIELD_NAME"
                      rules=""
                      v-slot="{ errors }"
                    >
                      <date-picker v-model="termDetails.applied_date2" valueType="format"></date-picker>
                      <div>
                        <span class="error-msg-wrap">
                          <span class="error-msg">{{errors[0]}}</span>
                        </span>
                      </div>
                    </ValidationProvider>
                  </CCol>

                  <CCol lg="2" md="2">
                    <label class>{{$t("TERM_DETAILS_COMPONENT_PUBLISH")}}</label>
                  </CCol>
                  <CCol lg="4" md="4">
                    <div class="btn-switch-wrapper">
                      <label class="btn-switch">
                        <input type="checkbox" class="checkbox" v-model="termDetails.is_published" />
                        <span class="check-silder"></span>
                      </label>
                    </div>
                  </CCol>
                </CRow>

                <CTabs class="mt-3" variant="tabs" :active-tab="0">
                  <CTab :title="$t('TERM_DETAILS_COMPONENT_ENGLISH_TAB_TITLE')">
                    <div class="mt-3">
                      <CRow class="form-group">
                        <CCol lg="12" md="12">
                          <label class>{{$t("TERM_DETAILS_COMPONENT_CONTENT_LABEL")}}:</label>
                        </CCol>
                        <CCol lg="12" md="12">
                          <ValidationProvider
                            name="TERM_DETAILS_COMPONENT_CONTENT_FIELD_NAME"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <div>
                              <yimo-vue-editor v-model="termDetails.content"></yimo-vue-editor>
                              <span class="error-msg-wrap">
                                <span class="error-msg">{{errors[0]}}</span>
                              </span>
                            </div>
                          </ValidationProvider>
                        </CCol>
                      </CRow>
                    </div>
                  </CTab>
                  <CTab :title="$t('TERM_DETAILS_COMPONENT_JAPANESE_TAB_TITLE')">
                    <div class="mt-3">
                      <CRow class="form-group">
                        <CCol lg="12" md="12">
                          <label class>{{$t("TERM_DETAILS_COMPONENT_CONTENT_LABEL")}}:</label>
                        </CCol>
                        <CCol lg="12" md="12">
                          <ValidationProvider
                            name="TERM_DETAILS_COMPONENT_CONTENT_FIELD_NAME"
                            rules
                            v-slot="{ errors }"
                          >
                            <div>
                              <yimo-vue-editor v-model="termDetails.ja_content"></yimo-vue-editor>
                              <span class="error-msg-wrap">
                                <span class="error-msg">{{errors[0]}}</span>
                              </span>
                            </div>
                          </ValidationProvider>
                        </CCol>
                      </CRow>
                    </div>
                  </CTab>
                </CTabs>

                <CRow class="form-actions mt-3">
                  <CCol lg="12" md="12">
                    <CButton
                      @click="cancel(dirty)"
                      class="btn btn-secondary btn-cancel"
                    >{{$t("TERM_DETAILS_COMPONENT_BUTTON_CANCEL")}}</CButton>

                    <CButton
                      @click="onSubmit()"
                      v-if="!isEdit"
                      color="primary"
                      class="btn btn-update btn-create"
                    >{{$t("TERM_DETAILS_COMPONENT_BUTTON_SAVE")}}</CButton>

                    <CButton
                      @click="onSubmit()"
                      v-if="isEdit && isAllowedUpdating"
                      color="primary"
                      class="btn btn-update"
                    >{{$t("TERM_DETAILS_COMPONENT_BUTTON_UPDATE")}}</CButton>
                  </CCol>
                </CRow>
              </CForm>
            </ValidationObserver>

            <CModal class="leave-page-modal" :show.sync="warningLeavePage" :centered="true">
              <div class>
                <div class="tit-leave">
                  <h4>{{$t("TERM_DETAILS_COMPONENT_LABEL_MODAL_TITLE_LEAVE")}}</h4>
                </div>
                <p class="text-modal">{{$t("TERM_DETAILS_COMPONENT_MODAL_CONTENT_UNSAVED_CHANGES")}}</p>
                <p
                  class="text-modal"
                >{{$t("TERM_DETAILS_COMPONENT_MODAL_QUESTION_UNSAVED_CHANGES")}}</p>
              </div>

              <template #footer>
                <CButton
                  class="btn btn-stay"
                  @click="warningLeavePage = false"
                  color="dark"
                  variant="outline"
                >{{$t("TERM_DETAILS_COMPONENT_BUTTON_STAY_ON_THIS_PAGE")}}</CButton>

                <router-link
                  class="btn btn-leave"
                  :to="termsLink"
                >{{$t("TERM_DETAILS_COMPONENT_TEXT_LEAVE_THIS_PAGE")}}</router-link>
              </template>
            </CModal>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { VclList } from 'vue-content-loading';
import moment from 'moment';
import endpoints from '@/constants/endpoints';
import responseHelper from '@/utils/response-helper';
import stringHelper from '@/utils/string-helper';
import StakingExplorerLink from '@/components/StakingExplorerLink';
import StakingCurrency from '@/components/StakingCurrency';
import StakingBadge from '@/components/StakingBadge';
import { MembershipOrderStatus } from '@/enums';
import { ClaimRequestStatus } from '@/enums';
import { Constants, env } from '@/constants';
import YimoVueEditor from 'yimo-vue-editor';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  name: 'AddTerm',
  components: {
    VclList,
    YimoVueEditor,
    DatePicker,
  },
  data() {
    return {
      termNo: null,
      termDetails: {
        is_published: false,
        applied_date2: null,
        content: null,
        ja_content: null,
      },
      isLoading: true,
      isAllowedUpdating: false,
      dateFormat: Constants.dateFormat,
      dateTimePickerFormat: 'yyyy-MM-dd',
      isEdit: false,
      warningLeavePage: false,
      termsLink: '/admin/setting/terms',
    };
  },
  computed: {
    ...mapGetters(['userPermissions']),
  },
  async mounted() {
    const termNo = this.$route.params.termNo;
    this.checkPermissions();
    if (termNo) {
      this.isEdit = true;
      this.termNo = termNo;
      this.getTermDetails(termNo);
    } else {
      this.isEdit = false;
      this.isLoading = false;
    }
  },
  methods: {
    checkPermissions() {
      if (this.userPermissions.length > 0) {
        this.userPermissions.forEach(item => {
          if (item.name === 'UPDATE_TERM') {
            this.isAllowedUpdating = true;
          }
        });
      }
    },
    clearFromDate() {
      this.termDetails.applied_date = null;
    },
    async getTermDetails(termNo) {
      try {
        const res = await this.$http.get(endpoints.getTermDetails(termNo));
        this.termDetails = res.data;

        if (this.termDetails.applied_date) {
          const date = moment(this.termDetails.applied_date);
          this.termDetails.applied_date2 = date.toDate();
        }

        this.isLoading = false;
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: responseHelper.getErrorMessage(err),
        });
      }
    },
    async onSubmit() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) {
        return;
      }

      // Trim html
      this.termDetails.ja_content = stringHelper.trimHtml(this.termDetails.ja_content);
      const content = stringHelper.trimHtml(this.termDetails.content);

      if (!content) {
        this.termDetails.content = content;
        return;
      }

      if (this.isEdit) {
        return this.updateTerm();
      }

      this.addTerm();
    },
    async updateTerm() {
      if (this.termDetails.isProcessing) {
        return;
      }

      this.termDetails.isProcessing = true;

      try {
        const data = {
          is_published: this.termDetails.is_published || false,
          applied_date: this.termDetails.applied_date2 || undefined,
          content: this.termDetails.content,
          ja_content: this.termDetails.ja_content || undefined,
        };
        const result = await this.$http.put(endpoints.updateTerm(this.termNo), data);

        this.termDetails.isProcessing = false;
        this.$notify({
          group: 'notifications',
          type: 'success',
          title: this.$t('TERM_DETAILS_COMPONENT_NOTIFICATION_SUCCESS_TITLE'),
          text: this.$t('TERM_DETAILS_COMPONENT_UPDATE_TERM_SUCCESS'),
        });
      } catch (err) {
        this.termDetails.isProcessing = false;

        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('TERM_DETAILS_COMPONENT_NOTIFICATION_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    async addTerm() {
      if (this.termDetails.isProcessing) {
        return;
      }

      this.termDetails.isProcessing = true;
      try {
        const data = {
          is_published: this.termDetails.is_published || false,
          applied_date: this.termDetails.applied_date2 || undefined,
          content: this.termDetails.content,
          ja_content: this.termDetails.ja_content || undefined,
        };
        const result = await this.$http.post(endpoints.createTerm, data);

        this.termDetails.isProcessing = false;
        this.$notify({
          group: 'notifications',
          type: 'success',
          title: this.$t('TERM_DETAILS_COMPONENT_NOTIFICATION_SUCCESS_TITLE'),
          text: this.$t('TERM_DETAILS_COMPONENT_CREATE_TERM_SUCCESS'),
        });

        this.$router.push(this.termsLink);
      } catch (err) {
        this.termDetails.isProcessing = false;
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('TERM_DETAILS_COMPONENT_NOTIFICATION_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    cancel(dirty) {
      if (dirty) {
        this.warningLeavePage = true;
      } else {
        this.$router.push(this.termsLink);
      }
    },
  },
};
</script>
<style lang="scss">
.term-details-container {
  &.wrapper {
    padding: 0 24px;
  }

  .card {
    > .card-header {
      border-bottom: none;
      padding-bottom: 0px;

      h1 {
        color: #657187;
        font-size: 18px;
        font-weight: 600;
        line-height: 34px;
        margin-bottom: 0px;
      }
    }

    .tab-content {
      padding: 10px 10px 0 10px;
    }
    .form-control-plaintext {
      color: #3a4a7f;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 22px;
    }

    .form-group {
      margin-bottom: 0.5rem;
    }

    .form-actions {
      text-align: right;
      .btn {
        border: none;
        border-radius: 32px;
        width: 145px;
        height: 35px;
        padding: 0 30px;
        color: #fff;
        margin: 0 0 15px 15px;

        &.btn-primary {
          background: #3b4b7e;
        }

        &.btn-update {
          color: #fff;
          font-weight: 500;
          letter-spacing: 0.5px;
          text-align: center;
          background-color: #3b4b7e;
          border: 1px solid #3b4b7e;
          border-radius: 4px;
        }

        &.btn-cancel {
          background-color: transparent;
          color: #6c65e4;
          font-size: 14px;
          letter-spacing: 0;
        }
      }
    }
  }
}
</style>
